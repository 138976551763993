<template>
  <div id="splash">
    <div class="container">
      <div class="container-inner">
        <img class="brand" src="@/assets/btf-logo.png" alt="Break the Floor" width="300" />
        <h1>New Drops Coming Soon...</h1>
        <h2>Sign up for our mailing list to be notified when the drop launches.</h2>

        <form id="signup-form" @submit.prevent="formSubmit" v-if="!formSubmitted" :class="{ 'disabled': formSubmitting }">
          <input type="email" placeholder="EMAIL" v-model="formEmail" />
          <input type="submit" />
        </form>

        <div id="signup-form-error" v-if="formError">
          <p>{{ formError }}</p>
        </div>

        <div id="signup-form-success" v-if="formSuccess">
          <p>{{ formSuccess }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  export default {
    data: function () {
      return {
        formEmail: '',
        formSubmitting: false,
        formSubmitted: false,
        formSuccess: null,
        formError: null,
      }
    },
    methods: {
      formSubmit: function () {
        this.formSuccess = null
        this.formError = null

        const apiRoute = 'https://api.dance360.com/api/breakthefloor/user/update'

        var formData = {
          data: {
            email_address: this.formEmail,
            status: 'subscribed',
            status_if_new: 'pending',
            merge_fields: {},
            interests: {
              'c234e221e4': true,
              'a30a4a84a5': true,
              '45b8227ff2': true,
              '1aed488c41': true
            },
            list_id: '796e9610c9'
          }
        }
        // console.log(formData)
        var vm = this

        vm.formSubmitting = true
        axios.post(apiRoute, formData)
          .then(function (res) {
            // console.log(res)

            if (res.data.status === 'pending') {
              vm.formSuccess = 'We have sent you an email to confirm your subscription'
              vm.formSubmitted = true
            } else if (res.data.status === 'subscribed') {
              vm.formSuccess = 'You are already subscribed'
              vm.formSubmitted = true
            } else {
              vm.formError = 'A valid email address is required'
              vm.formSubmitted = false
            }

            vm.formSubmitting = false
          })
          .catch(function (err) {
            // console.log(err.response)
            // var status = err.response.status
            // if (status === 400 && err.response.data.message === 'duplicate_submission') {
            //   vm.formError = 'This email has already been entered'
            // } else if (status === 400) {
            //   vm.formError = err.response.data.message
            // } else if (status === 404) {
            //   vm.formError = 'There was an error'
            // } else if (status === 500) {
            //   vm.formError = 'There was an error'
            // }
            console.log(err.response)
            vm.formError = 'There was an error. Please try again later'
            vm.formSubmitting = false
          })
      }
    }
  }
</script>


<style lang="scss">
  html, body {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    font-family: 'Poppins';
    background-color: #000;
    background-image: url('~@/assets/bg.jpg');
    background-size: cover;
    background-position: center center;
  }

  #splash {
    width: 100%;
    height: 100%;
    min-width: 100vw;
    min-height: 100vh;
    position: relative;
    display: block;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;

    .container {
      margin: 0;
      width: 100%;
      max-width: 800px;
      background-color: rgba(0,0,0,0.5);
      color: #fff;
      padding: 2rem;
      box-sizing: border-box;
      box-shadow: 0 0 10px #000;
    }

    .container-inner {
      border: 5px solid #fff;
      padding: 2rem;
    }

    h1 {
      font-size: 5em;
      font-weight: 900;
      line-height: 0.8em;
      margin-bottom: 2rem;
      @media screen and (max-width: 420px) {
        font-size: 3rem;
      }
    }

    h2 {
      font-size: 1.5em;
      font-weight: bold;
      line-height: 1em;
      margin-bottom: 1rem;
    }
  }

  #signup-form {
    font-size: 18px;
    box-sizing: border-box;
    input[type="email"] {
      display: block;
      box-sizing: border-box;
      width: 100%;
      font-size: 1em;
      padding: 1em;
      border: none;
      border-radius: 5px;
      margin-bottom: 1rem;
    }

    input[type="submit"] {
      display: block;
      width: auto;
      background-color: #222;
      color: #fff;
      font-size: 1em;
      padding: 1em 2em;
      border: none;
      margin: 0 0 0 auto;
      font-weight: bold;
      text-transform: uppercase;
      border-radius: 5px;
      letter-spacing: 2px;
      transition: 0.25s ease;
      -webkit-appearance: none;

      &:hover {
        background-color: #d40063;
      }
    }
    &.disabled {
      opacity: 0.8;
      pointer-events: none;
    }
  }

  #signup-form-success {
    p {
      font-size: 1.5em;
      font-weight: bold;
    }
  }

  #signup-form-error {
    p {
      color: #e20000;
      font-weight: bold;
    }
  }

  .brand {
    box-sizing: border-box;
    max-width: 100%;
  }
</style>
